@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

body {
  background: #efefef;
}

h1,
h2,
h3,
h4,
h5 {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
}

h1 {
  letter-spacing: 2px;
}

@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial,
                        'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
                        'Noto Color Emoji';@font-size-base: 13px;@heading-color: #336195;@text-color: #333333;@text-color-secondary: #444444;@primary-color: #336195;@padding-lg: 24px;@padding-md: 16px;@padding-sm: 12px;@padding-xs: 8px;@padding-xss: 4px;@height-base: 48px;@height-lg: 40px;@height-sm: 24px;@margin-lg: 24px;@margin-md: 16px;@margin-sm: 12px;@margin-xs: 8px;@margin-xss: 4px;@form-item-label-colon-margin-right: 8px;@form-item-label-colon-margin-left: 2px;@skeleton-color: rgba(130, 130, 130, 0.2);